// Showing a user a list of their chosen courses
import { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Stack,
  Alert,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  LinearProgress
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  doc,
  onSnapshot,
  getFirestore,
  addDoc,
  updateDoc,
  collection,
  Timestamp
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { checkWalletConnection } from '../modules/beacon';
import { formatNumber } from '../modules/utils';

const SaturnPoolContent = () => {
  const navigate = useNavigate();
  const { stakedId } = useParams();
  const [stake, setStake] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [masterclass, setMasterclass] = useState('');
  const [userAddress, setUserAddress] = useState();
  const [emailSent, setEmailSent] = useState(false);

  const masterclassOptions = {
    9713: 'IDO Mastermind',
    9712: 'NFT Mastermind'
  };

  // check if the user is connected on every page load
  useEffect(() => {
    console.log('checking the user on component load...');
    const checkUser = async () => {
      const address = await checkWalletConnection();
      console.log(address);
      setUserAddress(address);
      if (address) {
        onSnapshot(
          doc(getFirestore(), 'users', address, 'staked', stakedId),
          async stakedSnapshot => {
            if (
              stakedSnapshot.data() &&
              stakedSnapshot.data().pool === 'Saturn'
            ) {
              setStake(stakedSnapshot.data());
            } else {
              navigate('/', { replace: true });
            }
          }
        );
      } else {
        navigate('/', { replace: true });
      }
    };

    checkUser();
  }, [navigate, stakedId]);

  if (!stake) {
    return <LinearProgress color="secondary" sx={{ mt: 11, mb: 88 }} />;
  }

  return (
    <Grid container justifyContent="center" sx={{ minHeight: '88vh' }}>
      <Grid item xs={11} sm={6}>
        <Stack spacing={3} my={11} pt={3}>
          <Typography variant="h5" align="center">
            Thanks for staking in the Saturn Pool
          </Typography>
          <Typography variant="body1" align="center">
            You staked {formatNumber(stake.amounts[stake.coin])}{' '}
            {stake.coin.toUpperCase()} ($
            {formatNumber(stake.amounts.usd)} USD)
          </Typography>
          {emailSent && (
            <Alert severity="info">
              Thanks! Your details have been submitted.
            </Alert>
          )}
          {!emailSent && (
            <>
              <Typography variant="body1" align="center">
                To register for the masterclass, please enter your details
                below.
              </Typography>
              <TextField
                label="Name"
                value={name}
                onChange={evt => setName(evt.target.value)}
              />
              <TextField
                label="Email address"
                value={email}
                onChange={evt => setEmail(evt.target.value)}
              />
              <FormControl>
                <Select
                  value={masterclass}
                  label="Masterclass options"
                  onChange={evt => setMasterclass(evt.target.value)}
                >
                  {Object.keys(masterclassOptions).map(id => (
                    <MenuItem value={id}>{masterclassOptions[id]}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>Choose your masterclass</FormHelperText>
              </FormControl>
              <Button
                variant="contained"
                size="large"
                disabled={!name || !email || !masterclass}
                onClick={async () => {
                  const courseRef = await addDoc(
                    collection(getFirestore(), 'users', userAddress, 'courses'),
                    {
                      timestamp: Timestamp.now(),
                      email,
                      name,
                      contentId: 'Masterclass',
                      title: masterclassOptions[masterclass]
                    }
                  );

                  await updateDoc(
                    doc(
                      getFirestore(),
                      'users',
                      userAddress,
                      'staked',
                      stakedId
                    ),
                    {
                      courseId: courseRef.id
                    }
                  );
                  const sendEmail = httpsCallable(getFunctions(), 'sendEmail');
                  const subject = `Form submission from Saturn pool`;
                  const body = `<p>You got a form submission from ${name} (${email}).</p>
                  <p>${name} staked ${formatNumber(
                    stake.amounts[stake.coin]
                  )} ${stake.coin.toUpperCase()} ($${formatNumber(
                    stake.amounts.usd
                  )} USD) for "${
                    masterclassOptions[masterclass]
                  }" on ${stake.timestamp.toDate()} - <a href="https://tzkt.io/${
                    stake.transactionHash
                  }">transaction details</a></p>
                  <p>MoneyHero AI</p>`;

                  await sendEmail({
                    to: 'hey@moneyhero.io',
                    subject,
                    body
                  });
                  await sendEmail({
                    to: 'sebastian@moneyhero.io',
                    subject,
                    body
                  });
                  const addContactToList = httpsCallable(
                    getFunctions(),
                    'addContactToList'
                  );
                  addContactToList({ name, email, listId: masterclass });

                  setEmailSent(true);
                }}
              >
                Submit
              </Button>
            </>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default SaturnPoolContent;
