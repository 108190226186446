import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { CssBaseline, LinearProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { getMyhValue, getXtzValue } from './modules/crypto-info';
import App from './screens/App';
import Content from './screens/Content';
import UserContent from './screens/UserContent';
import SaturnPoolContent from './screens/SaturnPoolContent';
import Header from './components/Header';
import Footer from './components/Footer';
import Boing from './fonts/boing-font/Boing_RegularWEB.woff';
import BoingBold from './fonts/boing-font/Boing_BoldWEB.woff';
import ScrollToTop from './components/ScrollToTop';

const theme = createTheme({
  typography: {
    fontFamily: 'Boing',
    h1: {
      fontWeight: 'bold'
    },
    h2: {
      fontWeight: 'bold'
    },
    h3: {
      fontWeight: 'bold'
    },
    h4: {
      fontWeight: 'bold'
    },
    h5: {
      fontWeight: 'bold'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Boing';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Boing'), local('Boing-Regular'), url(${Boing}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Boing';
          font-style: normal;
          font-display: swap;
          font-weight: bold;
          src: local('Boing'), local('Boing-Bold'), url(${BoingBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 22
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #43C0FF'
        }
      }
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#042e60'
    },
    secondary: {
      main: 'rgb(67, 192, 255)'
    },
    background: {
      default: '#131128',
      paper: '#042e60'
    },
    text: {
      primary: '#DEF9FF'
    }
  }
});

const CoreApp = () => {
  const [oneTezToMyh, setOneTezToMyh] = useState();
  const [oneTezToUsd, setOneTezToUsd] = useState();

  // get MYH and XTZ values
  useEffect(() => {
    const getMyhValueAsUSD = async () => {
      const myhValue = await getMyhValue();
      setOneTezToMyh(myhValue);
    };

    const getTezValue = async () => {
      const usdValue = await getXtzValue();
      setOneTezToUsd(usdValue);
    };

    getMyhValueAsUSD();
    getTezValue();
  }, []);

  if (!oneTezToUsd || !oneTezToMyh) {
    return <LinearProgress color="secondary" sx={{ mt: 8 }} />;
  }

  return (
    <>
      <Header oneTezToUsd={oneTezToUsd} oneTezToMyh={oneTezToMyh} />
      <Routes>
        <Route
          exact
          path="/"
          element={<App oneTezToUsd={oneTezToUsd} oneTezToMyh={oneTezToMyh} />}
        />
        <Route path="/content/:stakedId" element={<Content />} />
        <Route path="/saturnpool/:stakedId" element={<SaturnPoolContent />} />
        <Route path="/user" element={<UserContent />} />
      </Routes>
      <Footer />
    </>
  );
};

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CoreApp />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
