import { initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCZnWBVEuAwGmUCnRftr4I0ClCx0QjBhew',
  authDomain: 'moneyhero-staking.firebaseapp.com',
  projectId: 'moneyhero-staking',
  storageBucket: 'moneyhero-staking.appspot.com',
  messagingSenderId: '445488548140',
  appId: '1:445488548140:web:da18f3cc6441c57ab94489',
  measurementId: 'G-S48EJJ8FHE'
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const firestore = getFirestore(app);

export { firestore };
