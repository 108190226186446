import { Box, Stack, IconButton } from '@mui/material';
import { Instagram, Telegram, Facebook, Twitter } from '@mui/icons-material';

const socialMedia = (Icon, link) => (
  <IconButton size="large" href={link}>
    <Icon fontSize="inherit" />
  </IconButton>
);

const Footer = () => (
  <Box
    sx={{
      bgcolor: 'primary.dark',
      justifyContent: 'center',
      display: 'flex',
      p: 3
    }}
  >
    <Stack direction="row" spacing={2}>
      {socialMedia(Telegram, 'https://t.me/geldhelden')}
      {socialMedia(Instagram, 'https://instagram.com/moneyhero_io')}
      {socialMedia(Facebook, 'https://www.facebook.com/MoneyHeros')}
      {socialMedia(Twitter, 'https://twitter.com/moneyhero_io')}
    </Stack>
  </Box>
);

export default Footer;
