import { useState, useEffect } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  Drawer,
  Divider,
  Alert
} from '@mui/material';
import {
  // Menu,
  Login,
  Logout,
  Description,
  AttachMoney,
  Person,
  Home
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
  connectToWallet,
  monitorActiveAccount,
  checkWalletConnection,
  disconnectWallet
} from '../modules/beacon';
import { formatNumber } from '../modules/utils';
import logo from '../images/money-hero-logo.png';

const menuItem = (text, action, Icon) => (
  <ListItem disablePadding>
    <ListItemButton onClick={action}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={text} sx={{ textTransform: 'uppercase' }} />
    </ListItemButton>
  </ListItem>
);

const Header = ({ oneTezToUsd, oneTezToMyh }) => {
  const navigate = useNavigate();
  const [userAddress, setUserAddress] = useState();
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    monitorActiveAccount(user =>
      user ? setUserAddress(user.address) : setUserAddress(null)
    );

    const checkConnection = async () => {
      const address = await checkWalletConnection();
      setUserAddress(address);
    };

    checkConnection();
  }, []);

  return (
    <AppBar position="fixed" sx={{ bgcolor: 'primary.dark', pt: 1 }}>
      <Toolbar>
        <Box flexGrow={1}>
          <Box
            sx={{ '&:hover': { cursor: 'pointer' }, display: 'inline-flex' }}
          >
            <img
              src={logo}
              alt="MoneyHero logo"
              onClick={() => navigate('/', { replace: true })}
            />
          </Box>
        </Box>
        <Button
          color="secondary"
          onClick={() => setShowDrawer(true)}
          variant="outlined"
        >
          Menu
        </Button>
        {/* <IconButton onClick={() => setShowDrawer(true)} color="secondary">
          <Menu />
        </IconButton> */}
        <Drawer
          anchor={'right'}
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
        >
          <List sx={{ width: 333 }}>
            {userAddress ? (
              <ListItem>
                <ListItemText
                  primary={`${userAddress.substr(0, 8)}...${userAddress.substr(
                    -4
                  )}`}
                  secondary="XTZ address"
                />
              </ListItem>
            ) : (
              menuItem(
                'Connect',
                () => {
                  connectToWallet();
                  setShowDrawer(false);
                },
                Login
              )
            )}
            <Divider />
            {menuItem(
              'Home',
              () => {
                navigate('/');
                setShowDrawer(false);
              },
              Home
            )}
            {userAddress &&
              menuItem(
                'Your courses',
                () => {
                  navigate('/user');
                  setShowDrawer(false);
                },
                Person
              )}
            {menuItem(
              'Docs',
              () =>
                (window.location.href =
                  'https://en.moneyhero.io/topics/5705316'),

              Description
            )}
            {menuItem(
              'Buy MYH',
              () => (window.location.href = 'https://moneyhero.io/buy-myh'),
              AttachMoney
            )}
            <Divider />
            {userAddress &&
              menuItem(
                'Disconnect',
                () => {
                  disconnectWallet();
                  setShowDrawer(false);
                  navigate('/');
                },
                Logout
              )}
            <Divider />
            <Alert severity="info" variant="outlined" sx={{ margin: 3 }}>
              $1 USD = {formatNumber(oneTezToMyh / oneTezToUsd)} MYH
            </Alert>
          </List>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
