import { BeaconEvent } from '@airgap/beacon-sdk';
import { TezosToolkit } from '@taquito/taquito';
import { BeaconWallet } from '@taquito/beacon-wallet';
import {
  collection,
  addDoc,
  setDoc,
  getDoc,
  updateDoc,
  doc,
  Timestamp
} from 'firebase/firestore';
import BigNumber from 'bignumber.js';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { firestore } from '../modules/firebase';

// console.log(process.env.REACT_APP_NETWORK_URL);
// console.log(process.env.REACT_APP_NETWORK);

const Tezos = new TezosToolkit(process.env.REACT_APP_NETWORK_URL);
const wallet = new BeaconWallet({
  name: 'MoneyHero Staking',
  preferredNetwork: process.env.REACT_APP_NETWORK
});
Tezos.setWalletProvider(wallet);

// const dAppClient = new DAppClient({ name: 'MoneyHero Staking' });

// bring up the dialog to connect a user to their wallet
const connectToWallet = async () => {
  // const permissions = await dAppClient.requestPermissions();
  // const { address } = permissions;
  await wallet.requestPermissions({
    network: {
      type: process.env.REACT_APP_NETWORK
    }
  });
  // console.log('permissions done');
  const address = await wallet.getPKH();
  // console.log('New connection:', address);
  const timeNow = Timestamp.now();

  // check if the user has ever logged in before
  const userDoc = await getDoc(doc(firestore, 'users', address));

  if (userDoc.exists()) {
    updateDoc(userDoc.ref, {
      'timestamps.lastConnectedToWallet': timeNow
    });
  } else {
    setDoc(userDoc.ref, {
      timestamps: {
        firstConnectedToWallet: timeNow,
        lastConnectedToWallet: timeNow
      }
    });
  }

  addDoc(collection(firestore, 'users', address, 'events'), {
    type: 'connectedToWallet',
    timestamp: timeNow,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    userAgent: navigator.userAgent
  });
  return address;
};

// disconnect from their wallet
// const disconnectWallet = async () => await dAppClient.clearActiveAccount();
const disconnectWallet = async () => await wallet.clearActiveAccount();

// check if the user is connected to a wallet
const checkWalletConnection = async () => {
  const activeAccount = await wallet.client.getActiveAccount();
  return activeAccount ? activeAccount.address : null;
};

// get deposits, returns [{nat, amount}]
const getDeposits = async (walletAddress, farmAddress, exponent) => {
  const contract = await Tezos.contract.at(farmAddress);
  const storage = await contract.storage();
  const vals = await storage['depositTimes'].get(walletAddress);
  const deposits = [];
  if (vals) {
    for (let i = 0; i < vals.length; i++) {
      const val = await storage['splitDeposits'].get({
        0: walletAddress,
        1: vals[i].valueOf() //nat = block number of deposit
      });

      deposits.push({
        nat: parseInt(vals[i].valueOf(), 10),
        amount: val.valueOf() / Math.pow(10, exponent)
      });
    }
  }
  return deposits;
};

const approve = async (walletAddress, farmAddress, lpTokenContract) => {
  const operator_params = [
    {
      add_operator: {
        owner: walletAddress,
        operator: farmAddress,
        token_id: 0
      }
    }
  ];

  const contract = await Tezos.wallet.at(lpTokenContract);
  const op = await contract.methods.update_operators(operator_params).send();
  await op.confirmation();
};

const unapprove = async (walletAddress, farmAddress, lpTokenContract) => {
  const operator_params = [
    {
      remove_operator: {
        owner: walletAddress,
        operator: farmAddress,
        token_id: 0
      }
    }
  ];

  const contract = await Tezos.wallet.at(lpTokenContract);
  const op = await contract.methods.update_operators(operator_params).send();
  await op.confirmation();
};

const withdraw = async (farmAddress, nat, amount, exponent) => {
  const contractFarm = await Tezos.wallet.at(farmAddress);

  try {
    const result = await contractFarm.methods
      .withdraw(nat, amount * Math.pow(10, exponent))
      .send();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const checkForApprove = async (
  walletAddress,
  farmAddress,
  betterCallUrl,
  tzStatsUrl
) => {
  // using better-call api to do the approve check
  // https://better-call.dev/docs#operation/get-bigmap-keys
  // console.log('Checking', walletAddress, 'against', farmAddress);
  const response = await fetch(`${betterCallUrl}?q=${walletAddress}`);
  const data = await response.json();

  for (let approved of data) {
    if (
      (approved.data.key.children[0].value === walletAddress &&
        approved.data.key.children[1].value) === farmAddress
    ) {
      // console.log('Found approved address in better call api');
      return true;
    }
  }

  // using tzstats api on our Firebase server
  const fetchData = httpsCallable(getFunctions(), 'fetchData');
  const res = await fetchData({ url: tzStatsUrl });

  for (let approved of res.data) {
    if (approved.key[0] === walletAddress && approved.key[1] === farmAddress) {
      // console.log('Found approved address in tzstats api');

      return true;
    }
  }

  // console.log("Couldn't find it");

  return false;

  // const contract = await Tezos.contract.at(process.env.REACT_APP_MYH_TOKEN);
  // const storage = await contract.storage();

  // console.log(storage);
};

const deposit = async (amount, farmAddress, exponent) => {
  const contractFarm = await Tezos.wallet.at(farmAddress);

  try {
    const result = await contractFarm.methods
      .deposit(amount * Math.pow(10, exponent))
      .send();
    await result.confirmation();
    return result;
  } catch (error) {
    console.error('error with deposit');
    console.error(error);
    throw error;
  }
};

const claim = async (farmAddress) => {
  const contractFarm = await Tezos.wallet.at(farmAddress);
  try {
    const result = await contractFarm.methods.claim(0).send();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const getTotalLpTokenBalance = async (address, farmAddress, exponent) => {
  const contract = await Tezos.contract.at(farmAddress);
  const storage = await contract.storage();
  const val = await storage['delegators'].get(address);
  return val ? val.lpTokenBalance.valueOf() / Math.pow(10, exponent) : 0;
};

const monitorActiveAccount = (cb) =>
  wallet.client.subscribeToEvent(BeaconEvent.ACTIVE_ACCOUNT_SET, (data) =>
    cb(data)
  );
const getUnclaimedRewards = async (
  farmAddress,
  walletAddress,
  depositDecimals
) => {
  const contract = await Tezos.contract.at(farmAddress);
  const storage = await contract.storage();

  let res = 0;

  //let walletAddress2 = 'tz1bNQoZiaqbYQMFn7Try2CJFQY9YU82WmDB';

  try {
    const delegatorRecord = await storage['delegators'].get(walletAddress);

    if (delegatorRecord) {
      const accRewardPerShareStart = new BigNumber(
        delegatorRecord.accumulatedRewardPerShareStart
      );
      const accRewardPerShareEnd = new BigNumber(
        storage.farm.accumulatedRewardPerShare
      );

      const accumulatedRewardPerShare = accRewardPerShareEnd.minus(
        accRewardPerShareStart
      );

      res = accumulatedRewardPerShare
        .multipliedBy(delegatorRecord.lpTokenBalance)
        .dividedBy(Math.pow(10, depositDecimals + 8));
    }

    console.log('res', res.toString());
  } catch (error) {
    console.log(error);
  }

  return res;
};

const getTVL_Rewards_APR = async (
  depositTokenPrice,
  rewardTokenPrice,
  farmAddress,
  depositDecimals,
  rewardDecimals
) => {
  try {
    const myContract = await Tezos.contract.at(farmAddress);
    const myStorage = await myContract.storage();

    // TVL is the multiplication of depositToken price and number of tokens
    const totalLPbalance = new BigNumber(myStorage.farmLpTokenBalance);
    const depositTokenPrice_BN = new BigNumber(depositTokenPrice);
    const tvl = depositTokenPrice_BN
      .multipliedBy(totalLPbalance)
      .dividedBy(Math.pow(10, depositDecimals));

    console.log('depositTokenPrice: ', depositTokenPrice.toString());
    console.log('totalLPbalance: ', totalLPbalance.toString());
    console.log('tvl ', tvl.toString());

    // Rewards per block
    const rewardsPerBlock = myStorage.farm.plannedRewards.rewardPerBlock;
    const rewards =
      (rewardsPerBlock.valueOf() / Math.pow(10, rewardDecimals)) * 2 * 60 * 24;
    console.log(farmAddress, rewards);

    //APR
    const secondsPerYear = 60 * 60 * 24 * 365;
    const BLOCKS_PER_YEAR = secondsPerYear / 30;
    const totalRewardPricePerYear =
      rewardTokenPrice * rewardsPerBlock * BLOCKS_PER_YEAR;
    const totalStakingTokenInPool = tvl;
    console.log(totalStakingTokenInPool);
    const apr =
      totalStakingTokenInPool !== 0
        ? ((totalRewardPricePerYear / totalStakingTokenInPool) * 100) /
          Math.pow(10, rewardDecimals)
        : 0;
    console.log(farmAddress, apr);

    return { tvl, rewards, apr };
  } catch (error) {
    console.error('error getting tvl, rewards, apr');
    console.error(error);
    return { tvl: 0, rewards: 0, apr: 0 };
  }
};

// 1 XTZ to QPT
const getLpTokenValue = async () => {
  const contract = await Tezos.wallet.at(process.env.REACT_APP_LP_TOKEN);
  let storage = await contract.storage();

  let tezPool = new BigNumber(storage.storage.tez_pool);
  let total_supply = new BigNumber(storage.storage.total_supply);

  //both the LP token and tez have 6 decimals
  //they are divided and decimals cancel
  let numberOfTezPerOneLPToken = tezPool.div(total_supply);

  // console.log(
  //   'numberOfTezPerOneLPToken: ',
  //   numberOfTezPerOneLPToken.toString()
  // );
  // console.log(
  //   '1 LP token = ',
  //   numberOfTezPerOneLPToken.multipliedBy(2).toString(),
  //   ' tez'
  // );
  return 1 / numberOfTezPerOneLPToken.multipliedBy(2).valueOf();
};

export {
  connectToWallet,
  monitorActiveAccount,
  checkWalletConnection,
  getDeposits,
  approve,
  unapprove,
  withdraw,
  checkForApprove,
  deposit,
  claim,
  getUnclaimedRewards,
  getTotalLpTokenBalance,
  disconnectWallet,
  getTVL_Rewards_APR,
  getLpTokenValue
};

/*
{
  "url": "https://mainnet.smartpy.io/chains/main/blocks/head/context/contracts/KT1T6rqWwQEF7xTDGSgF73bjBUUcELcRNYBWyy/script",
  "innerEvent": {
    "isTrusted": true
  },
  "name": "HttpRequestFailed",
  "message": "Request to https://mainnet.smartpy.io/chains/main/blocks/head/context/contracts/KT1T6rqWwQEF7xTDGSgF73bjBUUcELcRNYBWyy/script failed"
}



*/
