import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TableRow,
  TableBody,
  TableCell,
  Table,
  List,
  ListItem,
  ListItemText,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  CircularProgress,
  LinearProgress,
  Backdrop,
  Box,
  Typography,
  Tooltip,
  ClickAwayListener
} from '@mui/material';
import { MoneyOff, Info } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import delay from 'delay';
import Lottie from 'react-lottie-player';
import {
  connectToWallet,
  approve,
  deposit,
  getDeposits,
  getTotalLpTokenBalance,
  withdraw,
  claim,
  getUnclaimedRewards,
  getTVL_Rewards_APR
  // unapprove
} from '../modules/beacon';
import { formatNumber } from '../modules/utils';
import { addDoc, collection, Timestamp } from '@firebase/firestore';
import { firestore } from '../modules/firebase';
import space from '../images/pool-background.json';

const PoolCard = ({
  pool,
  token,
  background,
  subHeader,
  lockPeriod,
  tokenExponent,
  userAddress,
  userBalance,
  depositTokenUsdRate,
  rewardTokenUsdRate,
  farmAddress,
  lpTokenContract,
  withdrawalPercentageFee,
  stakeTitle,
  stakeBody,
  minimumStake = 0,
  redirectPath,
  isApproved,
  buttonColour
}) => {
  const navigate = useNavigate();
  const [stakedAmount, setStakedAmount] = useState(0);
  const [deposits, setDeposits] = useState();
  const [tokenBalance, setTokenBalance] = useState();
  const [staking, setStaking] = useState(false);
  const [poolMetadata, setPoolMetadata] = useState();
  const [showStakingHistory, setShowStakingHistory] = useState(false);
  const [approved, setApproved] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState({
    APR: false,
    'Lock period': false,
    TVL: false,
    Rewards: false
  });
  const [unclaimedRewards, setUnclaimedRewards] = useState();
  const [snackbar, setSnackbar] = useState({ message: '', hideTime: null });
  const [backdrop, setBackdrop] = useState(false);
  const [currentBlock, setCurrentBlock] = useState(0);

  const rowData = (type, value, tooltip) => (
    <TableRow>
      <TableCell component="th" scope="row">
        {type}{' '}
        {tooltip && (
          <ClickAwayListener
            onClickAway={() =>
              setTooltipOpen({ ...tooltipOpen, [type]: false })
            }
          >
            <Tooltip
              PopperProps={{
                disablePortal: true
              }}
              onClose={() => setTooltipOpen({ ...tooltipOpen, [type]: false })}
              open={tooltipOpen[type]}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={tooltip}
              placement="top-end"
            >
              <IconButton
                onClick={() => setTooltipOpen({ ...tooltipOpen, [type]: true })}
                size="small"
              >
                <Info sx={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          </ClickAwayListener>
        )}
      </TableCell>
      <TableCell align="right">{value}</TableCell>
    </TableRow>
  );

  useEffect(() => {
    const getCardData = async () => {
      const poolData = await getTVL_Rewards_APR(
        depositTokenUsdRate,
        rewardTokenUsdRate,
        farmAddress,
        tokenExponent,
        8
      );
      setPoolMetadata(poolData);
    };

    getCardData();
  }, [farmAddress, depositTokenUsdRate, rewardTokenUsdRate, tokenExponent]);

  useEffect(() => {
    // const checkIfWalletAddressApproved = async () => {
    //   const approvedResult = await isApproved();
    //   setApproved(approvedResult);
    // };

    let interval;
    const startBalancesCheck = () => {
      interval = setInterval(async () => {
        const userDeposits = await getDeposits(
          userAddress,
          farmAddress,
          tokenExponent
        );
        setDeposits(userDeposits);
        const totalBalance = await getTotalLpTokenBalance(
          userAddress,
          farmAddress,
          tokenExponent
        );
        setTokenBalance(totalBalance);
        const unclaimed = await getUnclaimedRewards(
          farmAddress,
          userAddress,
          tokenExponent
        );
        setUnclaimedRewards(unclaimed);

        const res = await fetch('https://api.tzkt.io/v1/blocks/count');
        const blockData = await res.json();

        setCurrentBlock(blockData);
      }, 11000);
    };

    const checkWalletAddressApproved = async () => {
      const approvedResult = await isApproved();
      setApproved(approvedResult);
    };

    if (farmAddress && userAddress) {
      // checkIfWalletAddressApproved();
      checkWalletAddressApproved();
      startBalancesCheck();
    }

    return () => clearInterval(interval);
  }, [farmAddress, userAddress, tokenExponent, isApproved]);

  let availableActions;

  let disabledConfirm = true;
  if (parseInt(stakedAmount, 10) === 181) {
    disabledConfirm = false;
  } else if (
    !isNaN(stakedAmount) &&
    !(depositTokenUsdRate * stakedAmount < minimumStake)
  ) {
    disabledConfirm = false;
  }

  if (userAddress) {
    if (userBalance === undefined || !deposits || tokenBalance === undefined) {
      availableActions = (
        <LinearProgress color="secondary" sx={{ width: '100%' }} />
      );
    } else if (!approved) {
      availableActions = (
        <Button
          color={buttonColour}
          variant="contained"
          onClick={async () => {
            setBackdrop(true);
            setSnackbar({
              message: 'About to approve address...',
              hideTime: null
            });
            await approve(userAddress, farmAddress, lpTokenContract);

            while (!approved) {
              console.log(approved);
              const approvedResult = await isApproved();
              console.log(approvedResult);
              setApproved(approvedResult);
              await delay(1100);
            }
            setSnackbar({ message: '', hideTime: null });
            setBackdrop(false);
          }}
        >
          Approve
        </Button>
      );
    } else if (userBalance > 0 || unclaimedRewards > 0 || deposits.length > 0) {
      availableActions = (
        <>
          <Box justifyContent="space-around" display="flex" flex="1">
            {userBalance > 0 && (
              <Button
                color={buttonColour}
                variant="contained"
                style={{ width: '30%' }}
                onClick={() => setStaking(true)}
              >
                Stake
              </Button>
            )}
            {unclaimedRewards > 0 ? (
              <Button
                color={buttonColour}
                variant="contained"
                style={{ width: '30%' }}
                onClick={async () => {
                  const res = await claim(farmAddress);
                  console.log(res);
                  setSnackbar({ message: 'Claim completed', hideTime: 5500 });
                }}
              >
                Claim
              </Button>
            ) : (
              <Button style={{ width: '30%' }} variant="outlined" disabled>
                Claim
              </Button>
            )}

            {deposits.length > 0 && (
              <Button
                color={buttonColour}
                variant="contained"
                style={{ width: '30%' }}
                onClick={() => setShowStakingHistory(true)}
              >
                Withdraw
              </Button>
            )}
          </Box>

          <Dialog open={staking} onClose={() => setStaking(false)}>
            <DialogTitle style={{ fontWeight: 'bold', fontSize: 24 }}>
              {stakeTitle}
            </DialogTitle>
            <DialogContent>
              {stakeBody.map((sentence, i) => (
                <Typography key={i} variant="body1" gutterBottom>
                  {sentence}
                </Typography>
              ))}
            </DialogContent>

            <DialogContent>
              <TextField
                label={`Number of ${token} tokens to stake`}
                value={stakedAmount}
                onChange={evt => setStakedAmount(evt.target.value)}
                helperText={`Your available ${token} balance is ${formatNumber(
                  userBalance
                )}`}
              />
            </DialogContent>
            <DialogContent>
              <Alert severity="info" variant="outlined">
                {formatNumber(stakedAmount)} {token} = $
                {formatNumber(depositTokenUsdRate * stakedAmount)} USD
              </Alert>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={() => setStaking(false)}>
                Cancel
              </Button>
              <Button
                disabled={disabledConfirm}
                onClick={async () => {
                  setStaking(false);
                  setBackdrop(true);
                  setSnackbar({
                    message: `Staking ${formatNumber(
                      stakedAmount
                    )} ${token} tokens...`,
                    hideTime: null
                  });
                  console.log('about to deposit');
                  try {
                    const res = await deposit(
                      stakedAmount,
                      farmAddress,
                      tokenExponent
                    );
                    console.log(res);
                    console.log('deposited');
                    const stakedRef = await addDoc(
                      collection(firestore, 'users', userAddress, 'staked'),
                      {
                        timestamp: Timestamp.now(),
                        amounts: {
                          myh: parseInt(stakedAmount, 10),
                          usd: depositTokenUsdRate * stakedAmount
                        },
                        coin: 'myh',
                        pool,
                        transactionHash: res.opHash
                      }
                    );
                    setStakedAmount(0);
                    setBackdrop(false);
                    setSnackbar({ message: '', hideTime: null });
                    if (redirectPath) {
                      navigate(`/${redirectPath}/${stakedRef.id}`);
                    }
                  } catch (error) {
                    console.log('error when clicked confirm');
                    console.log(error);
                    setSnackbar({
                      message: `Staking cancelled: ${error.message}`,
                      hideTime: 5500
                    });
                  }
                }}
                variant="contained"
                color="primary"
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    } else {
      availableActions = (
        <>
          <Alert severity="info" variant="outlined">
            You have no {token} available to stake
          </Alert>
          {/* <Button
            color={buttonColour}
            variant="contained"
            onClick={async () => {
              setSnackbar('About to unapprove address...');
              console.log(userAddress, farmAddress, lpTokenContract);
              await unapprove(userAddress, farmAddress, lpTokenContract);

              // while (!approved) {
              //   console.log(approved);
              //   const approvedResult = await isApproved();
              //   console.log(approvedResult);
              //   setApproved(approvedResult);
              //   await delay(1100);
              // }
            }}
          >
            Unapprove
          </Button> */}
        </>
      );
    }
  } else {
    availableActions = (
      <Button
        color={buttonColour}
        variant="outlined"
        onClick={() => connectToWallet()}
      >
        Connect
      </Button>
    );
  }
  if (!poolMetadata) {
    return <LinearProgress color="secondary" />;
  }

  const withdrawalText = 'Fee for early withdrawal';

  return (
    <Card
      sx={{
        minWidth: 333,
        // bgcolor: 'primary.main',
        // bgcolor: 'rgb(2,0,36)',
        background,
        paddingBottom: 1,
        position: 'relative'
      }}
    >
      <Lottie
        loop
        onMouseEnter={() => setShowAnimation(true)}
        onMouseLeave={() => setShowAnimation(false)}
        animationData={space}
        play={showAnimation}
        style={{ width: 333, position: 'absolute' }}
      />
      <CardHeader
        title={`${pool} Pool`}
        sx={{ textAlign: 'center' }}
        subheader={subHeader}
      />
      <CardContent>
        <Table>
          <TableBody>
            {rowData(
              'APR',
              `${formatNumber(poolMetadata.apr)}%`,
              'Annual percentage rate, annual rate of return.'
            )}
            {rowData(
              'Rewards',
              `${formatNumber(poolMetadata.rewards)} MYH / day`,
              `Rewards are paid for staking ${token} tokens. Rewards are calculated per block (Tezos block time is every 30 seconds). Displayed value is the amount of rewards paid out per day.`
            )}
            {rowData(
              'TVL',
              `$${formatNumber(poolMetadata.tvl)} USD`,
              `Total value locked. The amount of ${token} staked in this pool in USD. TVL is calculated live and may change as the token rate changes.`
            )}
            {lockPeriod
              ? rowData(
                  'Lock period',
                  lockPeriod,
                  'You will be charged a fee if you choose to withdraw from this pool before before the lock period expires. (further deposits do not affect the withdrawal fee of previous deposits)'
                )
              : rowData('Lock period', 'none')}
            {rowData(withdrawalText, `${withdrawalPercentageFee}%`)}
          </TableBody>
        </Table>
      </CardContent>
      {tokenBalance !== undefined && unclaimedRewards !== undefined && (
        <CardContent sx={{ pb: 3 }}>
          <Alert variant="filled" severity="info">
            <Typography variant="subtitle1">
              Total staked: {formatNumber(tokenBalance)} {token}
            </Typography>
            <Typography variant="subtitle1">
              Unclaimed rewards: {formatNumber(unclaimedRewards)} MYH
            </Typography>
          </Alert>
        </CardContent>
      )}
      <CardActions>{availableActions}</CardActions>

      {deposits && (
        <Dialog
          open={showStakingHistory}
          onClose={() => setShowStakingHistory(false)}
        >
          <DialogTitle>Deposits</DialogTitle>
          <DialogContent>
            <DialogContentText>Choose a deposit to withdraw</DialogContentText>
            <List dense>
              {deposits.map(
                deposit =>
                  deposit.amount > 0 && (
                    <ListItem
                      key={deposit.nat}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="withdraw"
                          onClick={async () => {
                            setShowStakingHistory(false);
                            await withdraw(
                              farmAddress,
                              deposit.nat,
                              deposit.amount,
                              tokenExponent
                            );

                            setSnackbar({
                              message:
                                'Withdraw complete. Updating deposit list...',
                              hideTime: 5500
                            });
                          }}
                        >
                          <MoneyOff />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary={`${formatNumber(deposit.amount)} ${token}`}
                        secondary={
                          currentBlock - deposit.nat < 2880 * 90
                            ? `${withdrawalText}: ${withdrawalPercentageFee}%`
                            : ''
                        }
                      />
                    </ListItem>
                  )
              )}
            </List>
          </DialogContent>
        </Dialog>
      )}
      <Backdrop open={backdrop} sx={{ zIndex: 2 }}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <Snackbar
        open={!!snackbar.message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message={snackbar.message}
        autoHideDuration={snackbar.hideTime}
      />
    </Card>
  );
};

export default PoolCard;
