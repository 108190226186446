// Showing a user a list of their chosen courses
import { useEffect, useState } from 'react';
import {
  Typography,
  Stack,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@mui/material';
import { Info } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
  collection,
  getDoc,
  doc,
  onSnapshot,
  getFirestore
} from 'firebase/firestore';
import { checkWalletConnection } from '../modules/beacon';

const UserContent = () => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState();
  const [total, setTotal] = useState();
  const [showCourseInfo, setShowCourseInfo] = useState(false);

  // check if the user is connected on every page load
  useEffect(() => {
    console.log('checking the user on component load...');
    const checkUser = async () => {
      const address = await checkWalletConnection();
      if (address) {
        onSnapshot(
          collection(getFirestore(), 'users', address, 'courses'),
          async coursesSnapshot => {
            setTotal(coursesSnapshot.docs.length);
            const availableCourses = [];
            for (const courseSnapshot of coursesSnapshot.docs) {
              const course = await getDoc(
                doc(getFirestore(), 'content', courseSnapshot.data().contentId)
              );
              if (course.exists()) {
                availableCourses.push({
                  ...course.data(),
                  id: courseSnapshot.id
                });
              }
            }
            setCourses(availableCourses);
          }
        );
      } else {
        navigate('/', { replace: true });
      }
    };

    checkUser();
  }, [navigate]);

  if (!courses) {
    return <LinearProgress color="secondary" sx={{ mt: 11, mb: 88 }} />;
  }

  return (
    <Stack spacing={3} alignItems="center" my={11} pt={3}>
      <Typography variant="h5" align="center">
        Courses you've previously staked for
      </Typography>
      <Box display="flex" alignItems="center">
        <Typography variant="body1" align="center">
          {courses.length} of {total} courses available
        </Typography>
        <IconButton onClick={() => setShowCourseInfo(true)}>
          <Info />
        </IconButton>
      </Box>
      <TableContainer component={Paper} sx={{ maxWidth: 1111 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Cover photo</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Expert</TableCell>
              <TableCell>Summary</TableCell>
              <TableCell>Course access</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses.map(contentDoc => (
              <TableRow key={contentDoc.id}>
                <TableCell>
                  <img
                    src={contentDoc.imageUrl}
                    alt={contentDoc.title}
                    style={{ width: 222 }}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {contentDoc.title}
                </TableCell>
                <TableCell>{contentDoc.expert}</TableCell>
                <TableCell>{contentDoc.summary}</TableCell>
                <TableCell sx={{ width: 137 }}>
                  <Button
                    color="secondary"
                    href={contentDoc.accessLink}
                    variant="outlined"
                  >
                    access
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={showCourseInfo} onClose={() => setShowCourseInfo(false)}>
        <DialogTitle>Your courses</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Some courses may become unavailable over time.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default UserContent;
