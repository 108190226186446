import { useEffect, useState } from 'react';
import {
  Typography,
  Stack,
  Grid,
  Collapse,
  Divider,
  Snackbar,
  Box,
  Alert,
  Button,
  IconButton,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import PoolCard from '../components/PoolCard';
import {
  monitorActiveAccount,
  checkWalletConnection,
  getLpTokenValue
} from '../modules/beacon';
import { getTokenBalance, getMyhQpTokenBalance } from '../modules/crypto-info';
import jupiter from '../images/jupiter.png';
import saturn from '../images/saturn.png';
import uranus from '../images/uranus.png';

const App = ({ oneTezToUsd, oneTezToMyh }) => {
  const theme = useTheme();
  const largeUp = useMediaQuery(theme.breakpoints.up('lg'));
  const [userAddress, setUserAddress] = useState();
  const [myhBalanceDetails, setMyhBalanceDetails] = useState();
  const [myhQpBalance, setMyhQpBalance] = useState();
  const [oneTezToMyhQp, setOneTezToMyhQp] = useState();
  const [showBeta, setShowBeta] = useState(false);
  const [showBlockchainInfo, setShowBlockchainInfo] = useState(true);

  // monitor the active account connection
  useEffect(() => {
    const initUser = async address => {
      setUserAddress(address);
      if (address) {
        const tokenDetails = await getTokenBalance(address, 'MYH');
        setMyhBalanceDetails(
          tokenDetails
            ? parseInt(tokenDetails.balance, 10) /
                Math.pow(10, parseInt(tokenDetails.token.metadata.decimals, 10))
            : 0
        );
        const myqpBalance = await getMyhQpTokenBalance(address);

        console.log('myqpBalance: ', myqpBalance);

        setMyhQpBalance(myqpBalance);
      }
    };

    const checkConnection = async () => {
      const address = await checkWalletConnection();
      initUser(address);
    };

    // monitor for wallet connection changes
    monitorActiveAccount(user => initUser(user ? user.address : null));

    const getLpTokenRate = async () => {
      const res = await getLpTokenValue();
      // console.log(res);
      setOneTezToMyhQp(res);
    };

    // check connection on first load
    setShowBeta(true);
    checkConnection();
    getLpTokenRate();
  }, []);

  return (
    <Stack spacing={3} alignItems="center" my={11} pt={3}>
      <Typography variant="h2" align="center">
        MoneyHero Staking portal
      </Typography>
      <Typography variant="h5" align="center" p={3}>
        Stake your $MYH tokens to earn rewards & unlock access to premium
        academy content!
      </Typography>
      <Collapse in={showBlockchainInfo}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowBlockchainInfo(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          severity="info"
        >
          Interactions with the blockchain can take up to 3 minutes, please be
          patient.
        </Alert>
      </Collapse>

      <Button
        variant="contained"
        href="https://en.moneyhero.io/posts/stake-myh-tokens-to-access-premium-content?utm_source=manual"
        target="_blank"
        color="secondary"
        style={{
          width: 150,
          height: 45,
          fontSize: '1.1em',
          color: 'white',
          borderRadius: 22,
          fontWeight: 'bold',
          textTransform: 'none'
        }}
      >
        Learn How
      </Button>

      <Grid
        container
        justifyContent="space-evenly"
        direction={largeUp ? 'row-reverse' : 'row'}
        alignItems="center"
      >
        <Grid item xs={12} sm={7} lg={4} sx={{ padding: 3 }}>
          <PoolCard
            pool="Jupiter"
            token="MYH"
            background="linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(9,64,121,1) 35%, rgba(67,192,255,1) 100%)"
            subHeader="Staking for online courses & summit access"
            lockPeriod="90 days"
            tokenExponent={8}
            userAddress={userAddress}
            userBalance={myhBalanceDetails}
            depositTokenUsdRate={oneTezToUsd / oneTezToMyh}
            rewardTokenUsdRate={oneTezToUsd / oneTezToMyh}
            farmAddress={process.env.REACT_APP_JUPITER_FARM_ADDRESS}
            lpTokenContract={process.env.REACT_APP_MYH_TOKEN}
            withdrawalPercentageFee={50}
            stakeTitle={'Choose amount to stake'}
            stakeBody={[
              'Stake at least $99.99 to get access to MoneyHero acadamy content.',
              'One course per stake can be chosen. $100 - video course, $200 - live coaching recording, $300 - online summit, $500 - live coaching'
            ]}
            redirectPath="content"
            isApproved={async () => {
              // using better-call api to do the approve check
              // https://better-call.dev/docs#operation/get-bigmap-keys
              // console.log(
              //   'Checking',
              //   userAddress,
              //   'against',
              //   process.env.REACT_APP_JUPITER_FARM_ADDRESS
              // );
              const response = await fetch(
                `${process.env.REACT_APP_GET_BIGMAP_KEYS}?q=${userAddress}&limit=999`
              );
              // console.log(
              //   `${process.env.REACT_APP_GET_BIGMAP_KEYS}?q=${userAddress}`
              // );
              const data = await response.json();
              // console.log('JUPITER POOL');
              // console.log(data);

              for (let approved of data) {
                if (
                  approved.key.address_0 === userAddress &&
                  approved.key.address_1 ===
                    process.env.REACT_APP_JUPITER_FARM_ADDRESS
                ) {
                  // console.log('Approval found for Jupiter farm');
                  // console.log(
                  //   approved.data.key.children[0].value,
                  //   userAddress,
                  //   approved.data.key.children[1].value,
                  //   process.env.REACT_APP_JUPITER_FARM_ADDRESS
                  // );

                  return true;
                }
              }

              // console.log("Couldn't find it");

              return false;
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} lg={4} sx={{ p: 5 }}>
          <Stack>
            <Box sx={{ p: 3, mb: 3 }}>
              <img src={jupiter} width="222" alt="Jupiter" />
            </Box>
            <Typography variant="body1" gutterBottom>
              You will receive an online course, an implementation course or a
              summit package, depending on the amount staked:
            </Typography>
            <Typography variant="body1">
              From 100 USD - online course of your choice
            </Typography>
            <Typography variant="body1">
              From 200 USD - implementation course recording
            </Typography>
            <Typography variant="body1">
              From 500 USD - summit package of your choice
            </Typography>
            <Typography variant="body1">
              P.S .: After staking you will automatically be redirected to a
              page where you can select your content. Please allow up to 3
              minutes for the blockchain to confirm the transaction and choose
              carefully, you can't change your choice later!
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Divider light variant="middle" sx={{ width: 333 }} />
      <Grid container justifyContent="space-evenly" alignItems="center">
        <Grid item xs={12} sm={7} lg={4} sx={{ padding: 3 }}>
          <PoolCard
            pool="Saturn"
            token="MYH"
            background="linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(9,64,121,1) 35%, rgba(67,192,255,1) 100%)"
            buttonColour="primary"
            subHeader="Staking for Masterclass access"
            lockPeriod="180 days"
            tokenExponent={8}
            userAddress={userAddress}
            userBalance={myhBalanceDetails}
            depositTokenUsdRate={oneTezToUsd / oneTezToMyh}
            rewardTokenUsdRate={oneTezToUsd / oneTezToMyh}
            farmAddress={process.env.REACT_APP_SATURN_FARM_ADDRESS}
            lpTokenContract={process.env.REACT_APP_MYH_TOKEN}
            withdrawalPercentageFee={25}
            stakeTitle={'Stake in this pool to access our masterclasses'}
            stakeBody={[
              'Stake at least $6000 to receive access to a MoneyHero masterclass.'
            ]}
            minimumStake={6000}
            redirectPath="saturnpool"
            isApproved={async () => {
              // using better-call api to do the approve check
              // https://better-call.dev/docs#operation/get-bigmap-keys
              // console.log(
              //   'Checking',
              //   userAddress,
              //   'against',
              //   process.env.REACT_APP_SATURN_FARM_ADDRESS
              // );
              const response = await fetch(
                `${process.env.REACT_APP_GET_BIGMAP_KEYS}?q=${userAddress}&limit=999`
              );
              const data = await response.json();

              for (let approved of data) {
                if (
                  approved.key.address_0 === userAddress &&
                  approved.key.address_1 ===
                    process.env.REACT_APP_SATURN_FARM_ADDRESS
                ) {
                  // console.log(
                  //   'Found approved address for Saturn pool in better call api'
                  // );
                  return true;
                }
              }

              // console.log("Couldn't find it");

              return false;
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} lg={4} sx={{ p: 5 }}>
          <Box sx={{ p: 3, mb: 3 }}>
            <img src={saturn} width="222" alt="Saturn" />
          </Box>
          <Typography variant="body1" gutterBottom>
            You will get access to a mastermind group of your choice if you
            stake in this pool.
          </Typography>
          <Typography variant="body1" gutterBottom>
            A minimum amount of $6000 needs to be staked in one transaction.
            Stake more to receive higher MYH rewards!
          </Typography>
          <Typography variant="body1" gutterBottom>
            P.S .: After staking you will automatically be redirected to a page
            where you can enter your email address and select the mastermind
            group of your choice. Please allow up to 3 minutes for the
            blockchain to confirm the transaction and choose carefully, you
            can't change your choice later!
          </Typography>
        </Grid>
      </Grid>
      <Divider light variant="middle" sx={{ width: 333 }} />
      <Grid
        container
        justifyContent="space-evenly"
        direction={largeUp ? 'row-reverse' : 'row'}
        alignItems="center"
      >
        <Grid item xs={12} sm={7} lg={4} sx={{ padding: 3 }}>
          <PoolCard
            pool="Uranus"
            token="QPT"
            background="rgba(2,0,36,1)"
            buttonColour="secondary"
            subHeader="Staking for liquidity pool tokens"
            tokenExponent={6}
            userAddress={userAddress}
            userBalance={myhQpBalance}
            depositTokenUsdRate={oneTezToUsd / oneTezToMyhQp}
            rewardTokenUsdRate={oneTezToUsd / oneTezToMyh}
            farmAddress={process.env.REACT_APP_URANUS_FARM_ADDRESS}
            lpTokenContract={process.env.REACT_APP_LP_TOKEN}
            stakeTitle={'Stake LP tokens in this pool to earn $MYH rewards'}
            stakeBody={[
              '⚠️ No access to academy content for staking in this pool!'
            ]}
            withdrawalPercentageFee={0}
            isApproved={async () => {
              /*
            let userAddress2 = 'tz1gaa85iPHdLPfEbG4Qpwy9HYzCMLXTiods'; //yes
            let userAddress3 = 'tz1haZBXV1YBzVVGji9m6AsEASft5euF82K7'; //yes
            let userAddress4 = 'tz1UeuJvgJXArgTvsf6WKvydX4rMj42QoNu3'; //no - other farm approved
            let userAddress5 = 'tz1Yu1qh4mk9ejf2MLxRNnRwdHoidXqoSqqm'; //no - no farms approved
            */

              //MYH-QPT
              try {
                let res = await fetch(
                  `https://api.tzkt.io/v1/contracts/KT1Ed11bNukFdYSc3qQFZ2HGYdF13XU6WZ4A/bigmaps/ledger/keys/${userAddress}`
                );
                const data = await res.json();
                let approvedContracts = data.value.allowances;

                for (let i = 0; i < approvedContracts.length; i++) {
                  console.log(`${i}: `, approvedContracts[i]);

                  if (
                    approvedContracts[i] ===
                    'KT1CHKuzz1SXCimdtiLgt4X8rkuybY3shaTe'
                  ) {
                    //Uranus pool address
                    console.log('approval found!');
                    return true;
                  }
                }
                return false;
              } catch (error) {
                console.log(error);
                console.log('no approval found!');
                return false;
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} lg={4} sx={{ p: 5 }}>
          <Box sx={{ p: 3, mb: 3 }}>
            <img src={uranus} width="222" alt="Uranus" />
          </Box>
          <Typography variant="body1" gutterBottom>
            You need to provide liquidity on Quipuswap first to be able to stake
            and receive rewards from this pool.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Please understand the chances and risks involved in liquidity mining
            before participating in this pool.
          </Typography>
          <Box display="flex" justifyContent="space-around" sx={{ mt: 3 }}>
            <Button
              href="https://en.moneyhero.io/posts/myh-liquidity-mining-tutorial?utm_source=manual"
              color="secondary"
            >
              Tutorial
            </Button>
            <Button
              href="https://quipuswap.com/invest/add-liquidity/KT1BB1uMwVvJ1M3vVHXWALs1RWdgTp1rnXTR_0"
              color="secondary"
            >
              Provide liquidity
            </Button>
            {/* <a
              style={{ color: 'white' }}
              href=""
            >
              Tutorial
            </a>
            <a
              style={{ color: 'white' }}
              href="https://quipuswap.com/invest/add-liquidity/KT1BB1uMwVvJ1M3vVHXWALs1RWdgTp1rnXTR_0"
            >
              Provide liquidity
            </a> */}
          </Box>
        </Grid>
      </Grid>

      {/* </Stack> */}
      <Snackbar
        open={!!showBeta}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setShowBeta(false)}
        autoHideDuration={8800}
        action={
          <Button
            href="https://en.moneyhero.io/posts/myh-staking-troubleshooting-support?utm_source=stakingportal"
            target="_blank"
          >
            Report an error
          </Button>
        }
        message={<p>Staking is in Beta mode. Use at your own risk.</p>}
      />
    </Stack>
  );
};

export default App;
