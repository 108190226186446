const getTokenBalances = userAddress =>
  fetch(
    `https://api.tzkt.io/v1/tokens/balances?account=${userAddress}&limit=555`
  )
    .then(res => res.json())
    .then(data => {
      return data || [];
    })
    .catch(e => {
      console.error(e);
    });

const getTokenBalance = async (userAddress, tokenSymbol) => {
  const balances = await getTokenBalances(userAddress);
  return balances.find(
    tokenDetails => tokenDetails.token.metadata.symbol === tokenSymbol
  );
};

const getMyhQpTokenBalance = async userAddress => {
  //get CVZA-QPT token details
  let myh_qptBal;
  try {
    const res = await fetch(
      `https://api.tzkt.io/v1/bigmaps/4538/keys?key=${userAddress}`
    );
    const data = await res.json();
    myh_qptBal = await data[0].value.balance;
    console.log('myh_qpt balance: ', myh_qptBal);
  } catch {
    myh_qptBal = '0';
  }

  return Number(myh_qptBal) / Math.pow(10, 6);
};

// 1 XTZ to MYH
const getMyhValue = async () =>
  fetch(
    'https://api.tzkt.io/v1/contracts/KT1Ed11bNukFdYSc3qQFZ2HGYdF13XU6WZ4A/storage/history?limit=1'
  )
    .then(res => res.json())
    .then(data => {
      const { tez_pool, token_pool } = data[0].value.storage;
      return token_pool / tez_pool / 100;
    })
    .catch(e => {
      console.error(e);
    });

// 1 XTZ to USD
const getXtzValue = async () =>
  fetch(
    'https://api.coingecko.com/api/v3/simple/price?ids=tezos&vs_currencies=usd&include_market_cap=true&include_24hr_vol=true&include_24hr_change=true&include_last_updated_at=true'
  )
    .then(res => res.json())
    .then(data => {
      return data.tezos.usd;
    })
    .catch(e => {
      console.error(e);
    });

// // 1 LP token to XTZ value
// const getLpTokenValue = async () => {
//   const contract = await Tezos.wallet.at(process.env.REACT_APP_LP_TOKEN);
//   let storage = await contract.storage();

//   let tezPool = new BigNumber(storage.storage.tez_pool);
//   let total_supply = new BigNumber(storage.storage.total_supply);

//   //both the LP token and tez have 6 decimals
//   //they are divided and decimals cancel
//   let numberOfTezPerOneLPToken = tezPool.div(total_supply);

//   console.log(
//     'numberOfTezPerOneLPToken: ',
//     numberOfTezPerOneLPToken.toString()
//   );
//   console.log(
//     '1 LP token = ',
//     numberOfTezPerOneLPToken.multipliedBy(2).toString(),
//     ' tez'
//   );
// };

export { getTokenBalance, getMyhValue, getXtzValue, getMyhQpTokenBalance };
