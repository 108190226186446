/*
 solution from https://stackoverflow.com/a/54343182/2813041
 
 But:
    - added navigate in dependency list
    - using useNavigate hook instead

 */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default ScrollToTop;
