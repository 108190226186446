// letting a user choose a course based on what they staked
import { useEffect, useState } from 'react';
import {
  Typography,
  Stack,
  Grid,
  Box,
  Divider,
  LinearProgress,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Alert,
  AlertTitle,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  collection,
  getDocs,
  getDoc,
  doc,
  addDoc,
  updateDoc,
  onSnapshot,
  Timestamp,
  where,
  query,
  orderBy
} from 'firebase/firestore';
import { firestore } from '../modules/firebase';
import { getLanguageName, formatNumber } from '../modules/utils';
import { checkWalletConnection } from '../modules/beacon';
import logo from '../images/money-hero-success.png';

const Content = () => {
  const navigate = useNavigate();
  let { stakedId } = useParams();
  const [userAddress, setUserAddress] = useState();
  const [language, setLanguage] = useState('DE');
  const [stake, setStake] = useState();
  const [content, setContent] = useState();
  const [courseChoice, setCourseChoice] = useState();
  const [enrolledCourse, setEnrolledCourse] = useState();
  const [availableLanguages, setAvailableLanguages] = useState();

  // check if the user is connected on every page load
  useEffect(() => {
    console.log('checking the user on component load...');
    const checkUser = async () => {
      const address = await checkWalletConnection();
      if (address) {
        setUserAddress(address);

        onSnapshot(
          doc(firestore, 'users', address, 'staked', stakedId),
          async stakedSnapshot => {
            const stakedData = stakedSnapshot.data();
            setStake(stakedData);

            if (!stakedSnapshot.exists()) {
              // invalid stakeId, go back to the homepage
              navigate('/', { replace: true });
            } else if (stakedSnapshot.data().courseId) {
              // if there is a course for this stake, get it

              // enrollment data for this particular wallet address
              const enrollmentData = (
                await getDoc(
                  doc(
                    firestore,
                    'users',
                    address,
                    'courses',
                    stakedData.courseId
                  )
                )
              ).data();
              // general course data that the wallet address chose
              const courseData = (
                await getDoc(
                  doc(firestore, 'content', enrollmentData.contentId)
                )
              ).data();
              setEnrolledCourse({
                ...courseData,
                enrolledAt: enrollmentData.timestamp
              });
            } else {
              // set no course chosen for this stake yet
              setEnrolledCourse({});
            }
          }
        );
      } else {
        navigate('/', { replace: true });
      }
    };

    checkUser();
  }, [navigate, stakedId]);

  useEffect(() => {
    const getContent = async () => {
      const querySnapshot = await getDocs(
        query(
          collection(firestore, 'content'),
          where('value', '<=', stake.amounts.usd),
          orderBy('value', 'desc')
        )
      );
      const contentDocs = [];
      const languages = {};
      for (const contentSnapshot of querySnapshot.docs) {
        contentDocs.push({ ...contentSnapshot.data(), id: contentSnapshot.id });
        languages[contentSnapshot.data().languageCode] = true;
      }
      setAvailableLanguages(Object.keys(languages));
      for (const userLanguage of navigator.languages) {
        if (Object.keys(languages).includes(userLanguage)) {
          setLanguage(userLanguage);
        }
      }
      setContent(contentDocs);
    };

    if (stake) {
      getContent();
    }
  }, [stake]);

  if (!content || !stake || !userAddress || !enrolledCourse) {
    return <LinearProgress color="secondary" sx={{ mt: 11, mb: 88 }} />;
  }

  if (stake.courseId) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 11,
          mb: 33,
          minHeight: '55vh'
        }}
      >
        <Stack spacing={3} alignItems="center">
          <img src={logo} alt="MoneyHero logo" width={333} />
          <Alert variant="filled" severity="info" color="secondary">
            <AlertTitle>
              Stake of {formatNumber(stake.amounts[stake.coin])}{' '}
              {stake.coin.toUpperCase()} ($
              {formatNumber(stake.amounts.usd)} USD)
            </AlertTitle>
            <Typography gutterBottom>
              You chose to take the "{enrolledCourse.title}" course by{' '}
              {enrolledCourse.expert}.
            </Typography>
            <Button
              href={enrolledCourse.accessLink}
              target="_blank"
              variant="contained"
            >
              View course
            </Button>
          </Alert>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
            sx={{ mt: 3 }}
          >
            <Button href={'/user'} variant="contained" color="secondary">
              Your courses
            </Button>
            <Button href={'/'} variant="contained" color="secondary">
              Staking homepage
            </Button>
          </Stack>
        </Stack>
      </Box>
    );
  }

  return (
    <Stack spacing={3} alignItems="center" my={11} pt={3}>
      <img src={logo} alt="MoneyHero logo" width={128} />
      <Typography variant="h5" align="center" p={3}>
        Thanks for staking with us!
      </Typography>
      <Alert
        variant="filled"
        severity="success"
        color="secondary"
        sx={{ maxWidth: 555 }}
      >
        <AlertTitle>
          You staked {formatNumber(stake.amounts[stake.coin])}{' '}
          {stake.coin.toUpperCase()} (${formatNumber(stake.amounts.usd)} USD)
        </AlertTitle>
        {content.length > 0 && (
          <Typography>
            You can now choose 1 course from the courses below. Please note that
            your choice is final. All courses you stake for can be accessed
            anytime from "Your courses" in the menu.
          </Typography>
        )}
      </Alert>
      {content.length > 0 && (
        <>
          <Box sx={{ minWidth: 222, pt: 3 }}>
            <FormControl fullWidth>
              <InputLabel>Language</InputLabel>
              <Select
                value={language}
                label="Language"
                onChange={event => setLanguage(event.target.value)}
              >
                {availableLanguages.map(languageCode => (
                  <MenuItem value={languageCode} key={languageCode}>
                    {getLanguageName(languageCode)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Grid container>
            {content.map(contentDoc =>
              contentDoc.languageCode === language ? (
                <Grid item key={contentDoc.id} xs={12} md={4} sx={{ p: 3 }}>
                  <Card>
                    <CardHeader
                      title={contentDoc.title}
                      sx={{ minHeight: 128, display: 'block' }}
                    />
                    <CardMedia
                      component="img"
                      height="222"
                      image={contentDoc.imageUrl}
                    />
                    <CardContent>
                      <Typography variant="h6">{contentDoc.expert}</Typography>
                    </CardContent>
                    <CardContent sx={{ minHeight: 155 }}>
                      {contentDoc.summary.substring(0, 222)}
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'center', mb: 1 }}>
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => setCourseChoice(contentDoc)}
                      >
                        select
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ) : null
            )}
          </Grid>
        </>
      )}
      {courseChoice && (
        <Dialog open={!!courseChoice} onClose={() => setCourseChoice(null)}>
          <DialogTitle>Course confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please confirm your selection: {courseChoice.title} by{' '}
              {courseChoice.expert}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={() => setCourseChoice(null)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={async () => {
                setEnrolledCourse(null);
                const courseRef = await addDoc(
                  collection(firestore, 'users', userAddress, 'courses'),
                  {
                    contentId: courseChoice.id,
                    timestamp: Timestamp.now()
                  }
                );

                updateDoc(
                  doc(firestore, 'users', userAddress, 'staked', stakedId),
                  {
                    courseId: courseRef.id
                  }
                );
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Stack>
  );
};

export default Content;
